import { getRoutes } from "@lib/routes";
import { generateInternalLinkHref } from "@lib/utils";
import { PortableText, PortableTextComponents } from "@portabletext/react";
import { IRichtextSection } from "@repo/shared-types/src/api/helpers";
import Link from "next/link";

const routes = getRoutes();

export const components: PortableTextComponents = {
  block: {
    h2: ({ children }) => (
      <h2 className="mb-5 text-3xl font-bold md:text-4xl">{children}</h2>
    ),
    h3: ({ children }) => (
      <h3 className="mb-4 text-2xl font-bold md:text-3xl">{children}</h3>
    ),
    h4: ({ children }) => (
      <h4 className="mb-3 text-xl font-bold md:text-2xl">{children}</h4>
    ),
    h5: ({ children }) => (
      <h5 className="mb-2 text-[18px] font-bold  md:text-xl">{children}</h5>
    ),
    blockquote: ({ children }) => (
      <blockquote className="mb-5 border-l-2 pl-4 text-xl font-bold">
        {children}
      </blockquote>
    ),
    normal: ({ children }) => <p className="mb-5">{children}</p>,
  },
  list: {
    bullet: ({ children }) => (
      <ul className="list-disc [&>li]:mb-4 [&>li]:ml-4">{children}</ul>
    ),
    number: ({ children }) => (
      <ol className="list-decimal [&>li]:mb-4 [&>li]:ml-4">{children}</ol>
    ),
  },
  marks: {
    anchor: ({ children, value }) => (
      <Link
        className="font-bold text-primary hover:underline"
        href={`#${value.anchorTag}`}
      >
        {children}
      </Link>
    ),
    internalLink: ({ children, value }) => {
      const { pageType, slug, language, parentSlug, grandparentSlug } = value;
      const href = generateInternalLinkHref(
        pageType,
        slug,
        parentSlug,
        grandparentSlug,
        language
      );
      return (
        <Link href={href} className="font-bold text-primary hover:underline">
          {children}
        </Link>
      );
    },
    strong: ({ children }) => <strong>{children}</strong>,
    s: ({ children }) => <span className="line-through">{children}</span>,
    u: ({ children }) => <span className="underline">{children}</span>,
    poi: ({ value, children }) => {
      return (
        <Link
          href={{
            pathname: routes.webMap.route(),
            query: { poi: value.poiID },
          }}
          target="_blank"
          className="font-bold text-primary hover:underline"
        >
          {children}
        </Link>
      );
    },
    pdf: ({ value, children }) => {
      return (
        <a
          href={value.fileUrl}
          download
          className="font-bold text-primary hover:underline"
        >
          {children}
        </a>
      );
    },
    link: ({ value, children }) => {
      const target = (value?.href || "").startsWith("http")
        ? "_blank"
        : undefined;
      const rel = target === "_blank" ? "noindex nofollow" : undefined;
      return (
        <Link
          href={value?.href || ""}
          target={target}
          rel={rel}
          className="font-bold text-primary hover:underline"
        >
          {children}
        </Link>
      );
    },
    mailTo: ({ children, value }) => {
      return (
        <a
          href={`mailto:${value.mailAddress}`}
          className="font-bold text-primary hover:underline"
        >
          {children}
        </a>
      );
    },
    phonenumber: ({ children, value }) => {
      return (
        <a
          href={`tel:${value.phoneNumber.replace(/\s/g, "")}`}
          className="font-bold text-primary hover:underline"
        >
          {children}
        </a>
      );
    },
  },
};

export function RichtextSection({ richText }: IRichtextSection) {
  return (
    <section className="container mt-20 max-w-[847px] md:px-0">
      <PortableText value={richText} components={components} />
    </section>
  );
}
